var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.overStandardVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.overStandardVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "body-contain" },
            [
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "div",
                { on: { click: _vm.closeClick } },
                [
                  _c("en-icon", {
                    staticClass: "close-icon",
                    style: { fill: "#A9B5C6" },
                    attrs: { name: "guanbi-danchuang", size: "14px" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-contain" },
                [
                  _c("el-input", {
                    staticClass: "text-area",
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      maxlength: "100",
                      "show-word-limit": "",
                      placeholder: "请输入100字以内超标事由",
                    },
                    model: {
                      value: _vm.inputText,
                      callback: function ($$v) {
                        _vm.inputText = $$v
                      },
                      expression: "inputText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "en-button",
                {
                  staticClass: "confirm-button",
                  on: { click: _vm.confirmClick },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }